@import '../node_modules/prismjs/themes/prism-tomorrow';
// @import './assets/scss/app.scss';
@import './views/styles/app.scss';

.reportList {

    ul {
        margin: 0px;
    }

    li {
        font-size: 14px;
        margin-left: 25px;
        padding: 0px;
        line-height: 30px;
    }

    li a:link,
    li a:visited {
        color: #1e598e;
        text-decoration: none;

    }

    li a:hover {
        // color: #0A7CAF;
        // padding: 2px;
        font-weight: 600;
        // -moz-box-shadow: 0px 0px 12px #9e9ea3;
        // -webkit-box-shadow: 0px 0px 12px #9e9ea3;
        // box-shadow: 0px 0px 12px #9e9ea3;
        // border: none 0px #000000;
        // -moz-border-radius: 3px;
        // -webkit-border-radius: 3px;
        // border-radius: 3px;
    }
}