
.sticky-column{
    position: sticky !important;
    right: 0px;
    // border-left: 1px solid #F3F2F7;
    background-color: #ffffff;
    z-index: 1
}

.sticky-column-right{
    position: sticky !important;
    left: 0px;
    background-color: #ffffff;
    z-index: 1
}


// .sticky-column div{

//     background-color:#F3F2F7
// }

.rdt_TableCol:last-child {
    position: sticky;
    right: 0;
    //background-color:#F3F2F7;
    //border-left: 1px solid #F3F2F7;
}

.payment-main .react-dataTable .rdt_TableBody {
    overflow-y: auto;
}

@media print
{
  table { page-break-after:auto }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  tfoot { display:table-footer-group }
}


@media print {
    .rdt_TableCol:last-child {

        display: none;
      }

}

.menu-table {
  z-index: 1450 !important;
  transform: translate(-100px, 16.25px) !important;
  position: relative;
}

.menu-table-right {
  z-index: 1450 !important;
  transform: translate(-250px, 16.25px) !important;
  position: relative;
}

.menu-table-right[data-popper-placement]:not([data-popper-placement^=top-]) {
    top: -2.5rem !important;
}

.menu-table[data-popper-placement]:not([data-popper-placement^=top-]) {
    top: -2.5rem !important;
}

.menu-table-actions {
    z-index: 1500;
    transform: translate(100px, 10.25px) !important;
    position :fixed;
}

.menu-table-actions[data-popper-placement]:not([data-popper-placement^=top-]) {
    top: -2.5rem !important;
}
