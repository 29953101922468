.InventoryCustomOption {

    display: flex;
    padding: 5px;

    .img-container {

        width: 50px;

        img {
            border-radius: 5px;
            width: 50px;
            height: 50px;
        }
    }

    .contentx {

        padding: 0px 5px;
        flex-grow: 1;
        text-align: left;

    }

    .code {
        text-align: right;
        padding-left: 5px;
    }
}