.fix-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1;
    // border: 1px solid black;
    background-color: white;
    border: 1px solid lightgray;
}

.p-dot5 {
    padding: 0.5rem;
}

.fix-currency {
    position: absolute;
    right: 50px;
    top: 20px;

    .react-select {  
        min-width: 130px;
    }
}

.imp{
    color: red;
    font-size: 16px;
}


.overfollow-visible .ui-loader>div {
overflow: visible!important;
}


.payments-transaction .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action svg , .receiving-transactoin .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action svg  {
    stroke: white !important
}