.small-height-input .react-select {

    .select__value-container {

        padding: 0px 3px !important;
    }

    .select__indicator {
        padding: 0px 6px !important;
    }

    .select__control {
        // min-height: 24px !important;
    }

}

// input,select{
//     height: 30px!important;
// }



//simple form input
.input-control {
    height: 35px;
}

.itm-props-inputs {
    height: 35px
}

.s-input {
    height: 38px;
    font-size: 1rem;
    padding-top: 0px;
    padding-bottom: 0px;
}

.l-label {
    min-width: 100px;
    display: flex;
    font-size: 0.95rem;
    justify-content: start;
    padding-top: 5px;
}

.l-place-label {
    min-width: 120px;
    display: flex;
    font-size: 1rem;
    justify-content: start;
    padding-top: 5px;
}

.r-label {
    min-width: 110px;
    display: flex;
    justify-content: flex-end;
    padding-right: 15px
}

.small-label {
    min-width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.l-small-label {
    min-width: 84px;
    display: flex;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
}

.medium-label {
    min-width: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.l-medium-label {
    min-width: 95px;
    display: flex;
    font-size: 1rem;
    justify-content: flex-start;
    align-items: center;
}

.basic-shadow {
    box-shadow: 0 0 2px lightgray;
    border-radius: 4px;
    margin: 0px;
    padding: 0px;
}

.small-header {
    background-color: #7367f0;
    color: white;
    height: 30px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    align-items: center;
    display: flex;
    justify-content: center;
}

// react select input

.rs-input {
    height: 30px;
    min-height: 30px;
}

.rs-input .select__indicator {
    padding-top: 3px !important;
}

.rs-input .select__input-container {

    line-height: 1px;
}

@media screen and (max-width:768px) {}

.remarks-input {

    margin-left: 50px;
}


.Select-menu-outer {
    // bottom: 35px!important;
    // border-bottom-right-radius: 0px!important;
    // border-bottom-left-radius: 0px!important;
    // border-top-right-radius: 4px!important;
    // border-top-left-radius: 4px!important;

    position: fixed;

    top: auto;
    bottom: 100%;
}

.inventory-input .select__control {
    border-radius: 0px;
}



.in-green {
    background-color: #59ff80;
    color: black;
    font-weight: 600;

    &:focus {

        background-color: #59ff80;
        color: black;
        font-weight: 600;

    }
}

.invoice-totals-in {
    width: 370px;
    text-align: right;
    padding-top: 10px;
    padding-right: 20px;

}

.value-text {

    margin: 0px;
    margin-top: 10px;
    font-weight: bold;
}

@media screen and (max-width:800px) {

    .invoice-totals-in {
        width: 170px;
        text-align: left;
        font-size: 14px;
        padding-top: 10px;
        padding-right: 20px;
    }
}

// form switch
.inventory-property-tab .form-switch {
    display: flex;
}

.select__menu,
.select__menu-portal {
    z-index: 3 !important;

}

.form-switch {
    display: flex;
}

.inventory .inventory-textarea {
    min-height: 80px;
    max-height: 80px;
}

.inventory-cancel {
    background-color: #cd0708;
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    background-color: white;
}

.organizaion-box {
    color: black;
    font-weight: 400;
    font-size: 16px;
    height: 80px;
    border-radius: 10px;
    background-color: white;
}

.organizaion-box:hover {
    background-color: #f8f8f8;
    color: #7367f0;
    cursor: pointer;
}

.dark-layout {
    .organizaion-box {
        color: white;
        font-weight: 400;
        font-size: 16px;
        height: 80px;
        border-radius: 10px;
        background-color: #1e2431;
    }
    .organizaion-box:hover {
        background-color: rgba(40, 51, 75, 0.84);
        color: #7367f0;
        cursor: pointer;
    }
}

// ::-webkit-scrollbar
// {
// 	width: 12px;
// 	background-color: #F5F5F5;
// }

// ::-webkit-scrollbar-thumb
// {
// 	border-radius: 10px;
// 	background-color:blue;
// 	background-image: -webkit-linear-gradient(90deg,
// 											  transparent,
// 											  rgba(0, 0, 0, 0.4) 50%,
// 											  transparent,
// 											  transparent)
// }