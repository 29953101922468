@import './components/custom_inputs';
@import './components/react-table.scss';
@import './components/search-bar.scss';
@import './components/_print.scss';
@import './components/_table.scss';
@import './components/_invoice.scss';
@import './components/_inventory-custom-option.scss';
@import './components/layout.scss';

.s1 { color: #333; font-family:"Arial Black", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
p { color: #333; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; margin:0pt; }
.s2 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 28pt; }
.s3 { color: #333; font-family:"Arial Black", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
.s4 { color: #333; font-family:"Arial Black", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }
.s5 { color: #333; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
.s6 { color: #333; font-family:"Arial Black", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s7 { color: #333; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s8 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s9 { color: #727272; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s10 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s11 { color: black; font-family:"Arial Black", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s12 { color: #333; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
.s13 { color: #FFF; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s14 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s15 { color: black; font-family:"Arial Black", sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9pt; }
.s16 { color: #333; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
.s17 { color: #333; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
.s18 { color: #AAA; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 6pt; }

.form-label {

    color: black !important;
}

/* Custom styles for the card input */
.card-input {
    margin-bottom: 20px;
}

/* Label style for the card input */
.card-input label {
    display: block;
    font-size: 16px;
    margin-bottom: 6px;
}

/* Style for the CardElement container */
.CardElement {
    border: 1px solid #ccc !important; /* Add a border to the CardElement container */
    padding: 10px;
    border-radius: 4px;
}

.dark-layout {

    label,
    input::placeholder,
    .form-control {
        color: #d0d2d6 !important;
    }
    .form-label {

        color: white !important;
    }

}

.shepherd-arrow:before{
    background-color: #1F2054 !important;
}

.shepherd-element .shepherd-content .shepherd-footer {
    background-color: #fbfbfb;
    padding: 0 0.5rem 0.5rem !important;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible{
    opacity: 0 !important;
}

.shepherd-modal-overlay-container{
    pointer-events: visible !important;
}