@media print {

    .header-navbar,
    .main-menu,
    button,
    .dropdown-menu {
        display: none;

    }

    .rdt_TableCol:last-child {

      display: none!important;
    }
    

}