.fix-box-button{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 16px 10px;
    left:0;
    text-align: center;
    background-color: white;
    border-top: 1px solid lightgray;
}

.dark-layout .fix-box-button{

    background-color: #161d31;
}

.inv-item-fix-box-button{

    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 10px;
    background-color: inherit;
    left:0;
    text-align: center;
    background-color: #f8f8f8 !important;
}

.sidebar-form {
    height: calc(100% - 80px);
    padding-bottom: 100px;
}